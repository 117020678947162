<template>
    <div class="contact">
        <div class="intro">
            <p class="chapeau">{{$t('contact.chapeau')}}</p>
            <h1 class="mb-600" v-html="$t('contact.title')" />
        </div>
        <div class="options">
            <p class="chapeau">{{$t('contact.options')}}</p>
            <div class="grid">
                <div class="cell">
                    <h3 class="h4">{{$t('contact.email')}}</h3>
                    <p><a href="mailto:denisfrietman@mac.com" class="link">denisfrietman@mac.com</a> – {{$t('contact.emailText')}}</p>
                </div>
                <div class="cell">
                    <h3 class="h4">{{$t('contact.phone')}}</h3>
                    <p><a href="tel:0031641776001" class="link">{{$t('contact.phoneNumber')}}</a> – {{$t('contact.phoneText')}}</p>
                </div>
                <div class="cell">
                    <h3 class="h4">{{$t('contact.social')}}</h3>
                    <ul class="unstyle">
                        <li><i class="fab fa-linkedin"></i> <a href="https://www.linkedin.com/in/denisfrietman/" target="_blank">LinkedIn</a></li>
                        <li><i class="fab fa-instagram"></i> <a href="https://www.instagram.com/denisfrietman/" target="_blank">Instagram</a></li>
                        <li><i class="fab fa-behance"></i> <a href="https://www.behance.net/frietman" target="_blank">Behance</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {

}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Contact.scss';
</style>